import React, { useState } from "react";
import "../../styles/booking-form.css";
import { Form, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// If you want to show PaymentMethod in the same component, import it here:
// import PaymentMethod from "../path/to/PaymentMethod";

const BookingForm = ({ onBookingSubmit }) => {
  // Keep all the user's input (including date/time) in state
  const [bookingData, setBookingData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    pickupDate: null,
    pickupTime: null,
    dropoffDate: null,
    dropoffTime: null,
    promoCode: "",
    emergencyContact: "",
    driversLicense: "",
    passportNumber: "",
    message: "",
  });

  // For date fields (pickupDate, dropoffDate)
  const handleDateChange = (dateValue, fieldName) => {
    setBookingData((prev) => ({
      ...prev,
      [fieldName]: dateValue,
    }));
  };

  // For time fields (pickupTime, dropoffTime)
  const handleTimeChange = (timeValue, fieldName) => {
    setBookingData((prev) => ({
      ...prev,
      [fieldName]: timeValue,
    }));
  };

  // For text inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Called when user clicks "Proceed to Payment"
  const submitHandler = (event) => {
    event.preventDefault();

    // Require at least a driver’s license or a passport
    if (!bookingData.driversLicense && !bookingData.passportNumber) {
      alert("Please provide either a Driver's License OR a Passport Number.");
      return;
    }

    // Hand this data off to the parent OR
    // directly show <PaymentMethod> if you want to do it all in one file
    onBookingSubmit(bookingData);
  };

  return (
    <div className="container-fluid py-4">
      <div className="row justify-content-center">
        <div className="col-12">
          <div
            className="card shadow border-0"
            style={{
              maxWidth: "1200px",
              margin: "0 auto",
              background: "#f8f9fa",
            }}
          >
            <div className="card-body p-5">
              <h2 className="text-center mb-4">Booking Information</h2>

              <Form onSubmit={submitHandler}>
                {/* ROW 1: First Name & Last Name */}
                <div className="row g-0 mb-1">
                  <div className="col-md-6 pe-md-2">
                    <FormGroup className="booking__form">
                      <input
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        value={bookingData.firstName}
                        onChange={handleChange}
                        required
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6 ps-md-2">
                    <FormGroup className="booking__form">
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        value={bookingData.lastName}
                        onChange={handleChange}
                        required
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                </div>

                {/* ROW 2: Email & Phone Number */}
                <div className="row g-0 mb-1">
                  <div className="col-md-6 pe-md-2">
                    <FormGroup className="booking__form">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={bookingData.email}
                        onChange={handleChange}
                        required
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6 ps-md-2">
                    <FormGroup className="booking__form">
                      <input
                        type="number"
                        placeholder="Phone Number"
                        name="phoneNumber"
                        value={bookingData.phoneNumber}
                        onChange={handleChange}
                        required
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                </div>

                {/* ROW 3: Pickup Date & Pickup Time */}
                <div className="row g-0 mb-1">
                  {/* Pickup Date */}
                  <div className="col-md-6 pe-md-2">
                    <FormGroup className="booking__form">
                      <DatePicker
                        selected={bookingData.pickupDate}
                        onChange={(date) =>
                          handleDateChange(date, "pickupDate")
                        }
                        placeholderText="Pick-Up Date - From Airport"
                        dateFormat="MM/dd/yyyy"
                        className="form-control w-100"
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>

                  {/* Pickup Time */}
                  <div className="col-md-6 ps-md-2">
                    <FormGroup className="booking__form">
                      <DatePicker
                        selected={bookingData.pickupTime}
                        onChange={(time) =>
                          handleTimeChange(time, "pickupTime")
                        }
                        placeholderText="Pick-Up Time - From Airport"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control w-100"
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                </div>

                {/* ROW 4: Drop-Off Date & Drop-Off Time */}
                <div className="row g-0 mb-1">
                  {/* Drop-Off Date */}
                  <div className="col-md-6 pe-md-2">
                    <FormGroup className="booking__form">
                      <DatePicker
                        selected={bookingData.dropoffDate}
                        onChange={(date) =>
                          handleDateChange(date, "dropoffDate")
                        }
                        placeholderText="Drop-Off Date - To Airport"
                        dateFormat="MM/dd/yyyy"
                        className="form-control w-100"
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>

                  {/* Drop-Off Time */}
                  <div className="col-md-6 ps-md-2">
                    <FormGroup className="booking__form">
                      <DatePicker
                        selected={bookingData.dropoffTime}
                        onChange={(time) =>
                          handleTimeChange(time, "dropoffTime")
                        }
                        placeholderText="Drop-Off Time - To Airport"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control w-100"
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                </div>

                {/* ROW 5: Driver’s License & Passport */}
                <div className="row g-0 mb-1">
                  <div className="col-md-6 pe-md-2">
                    <FormGroup className="booking__form">
                      <input
                        type="text"
                        placeholder="Driver's License Number"
                        name="driversLicense"
                        value={bookingData.driversLicense}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6 ps-md-2">
                    <FormGroup className="booking__form">
                      <input
                        type="text"
                        placeholder="Passport Number "
                        name="passportNumber"
                        value={bookingData.passportNumber}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                </div>

                {/* ROW 6: Emergency Contact & Promo Code */}
                <div className="row g-0 mb-1">
                  <div className="col-md-6 pe-md-2">
                    <FormGroup className="booking__form">
                      <input
                        type="text"
                        placeholder="Additional/Emergency Contact"
                        name="emergencyContact"
                        value={bookingData.emergencyContact}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-md-6 ps-md-2">
                    <FormGroup className="booking__form">
                      <input
                        type="text"
                        placeholder="Promo/Discount Code (optional)"
                        name="promoCode"
                        value={bookingData.promoCode}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                </div>

                {/* ROW 7: Message (full width) */}
                <div className="row g-0 mb-4">
                  <div className="col-12">
                    <FormGroup className="booking__form">
                      <textarea
                        rows={4}
                        className="textarea"
                        placeholder="Message (optional)"
                        name="message"
                        value={bookingData.message}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                    </FormGroup>
                  </div>
                </div>

                {/* ROW 8: Submit Button */}
                <div className="row g-0">
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary w-100">
                      Proceed to Payment
                    </button>
                  </div>
                </div>
              </Form>

              {/* 
                If you want to render PaymentMethod in the SAME component,
                you can do something like:
                
                {showPayment && (
                  <PaymentMethod
                    amount={19000} // e.g. $190.00 in cents
                    bookingData={bookingData}
                    carItem={someCarObject}
                  />
                )}
                
                But that means you'd keep track of a state like 'showPayment'
                or possibly navigate to a different route. 
              */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
