import React, { useState } from "react";
import { Container, Row, Col, Collapse } from "reactstrap";
import "../styles/terms-and-conditions.css";

const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="collapsible-section">
      <h2 onClick={toggle} style={{ cursor: "pointer" }}>
        {title}
      </h2>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
};

const TermsAndConditions = () => {
  return (
    <Container className="terms-and-conditions my-5">
      <Row>
        <Col>
          <h1 className="mb-4">Important Info</h1>

          {/* Driver & License Requirements */}
          <CollapsibleSection title="Driver & License Requirements">
            <p>When you pick the car up, you'll need:</p>
            <ul>
              <li>Passport or national ID card</li>
              <li>Driving license</li>
              <li>Credit Card in the Main Driver's Name</li>
            </ul>

            <h3>License Requirements:</h3>
            <ul>
              <li>
                All drivers must present a full, valid driving license held for
                at least 12 months.
              </li>
              <li>All drivers must be a minumum of 20 years old.</li>
              <li>
                If the license is not in English, an International Driving
                Permit (IDP) or a certified translation is required.
              </li>
              <li>Digital driving licenses are not accepted.</li>
              <li>
                All drivers must be at least 18 years old. Drivers aged between
                18 and 25 may incur a young driver surcharge of NZ$25.00 per
                day.
              </li>
            </ul>
          </CollapsibleSection>

          {/* Security Deposit */}
          <CollapsibleSection title="Security Deposit">
            <p>
              <strong>NZ$2,300.00</strong>
            </p>
            <p>
              At pick-up, the main driver will leave a refundable security
              deposit of NZ$2,300.00 on their credit card. Cash and debit cards
              are not accepted. The counter staff will confirm how much this
              will be.
            </p>
            <h3>Accepted Cards</h3>
            <ul>
              <li>MasterCard</li>
              <li>Visa</li>
              <li>American Express</li>
            </ul>
            <p>
              <strong>Please Note:</strong>
            </p>
            <ul>
              <li>The credit card must be in the main driver's name.</li>
              <li>
                Credit cards must be activated for international transactions.
              </li>
              <li>
                We do not accept cash, prepaid, recharge, or virtual cards.
              </li>
            </ul>
          </CollapsibleSection>

          {/* Damage Excess */}
          <CollapsibleSection title="Damage Excess">
            <p>
              <strong>NZ$2,300.00</strong>
            </p>
            <p>
              If the car's bodywork gets damaged, the most you'll pay for
              repairs covered by the Collision Damage Waiver is the damage
              excess (NZ$2,300.00). This cover is only valid if you stick to the
              terms of the rental agreement. It doesn't cover other parts of the
              car (e.g. windows, wheels, interior or undercarriage), or charges
              (e.g. for towing or off-road time), or anything in the car (e.g.
              child seats, GPS devices or personal belongings).
            </p>
          </CollapsibleSection>

          {/* Fuel Policy */}
          <CollapsibleSection title="Fuel Policy">
            <p>
              <strong>Like for like</strong>
            </p>
            <p>
              When you pick your car up, the fuel tank will be full or partly
              full. You will leave a deposit to cover the cost of the fuel: the
              counter staff will block this money on your credit card. Just
              before you return your car, please replace the fuel you've used.
            </p>
            <ul>
              <li>
                Your rental car will be provided with a full tank of fuel.
              </li>
              <li>
                Please return the car with a full tank to avoid refueling
                charges.
              </li>
              <li>
                If the car is returned with less fuel, a refueling charge of
                NZ$25.00 plus the cost of the missing fuel will apply.
              </li>
            </ul>
          </CollapsibleSection>

          {/* Mileage */}
          <CollapsibleSection title="Mileage">
            <p>
              <strong>Unlimited Kilometers</strong>
            </p>
            <ul>
              <li>Your rental includes unlimited free kilometers.</li>
            </ul>
            <p>
              Please see the Service Provider’s full terms and conditions below,
              which include the full name and company registered address of your
              Service Provider, information on and fees of extra products and
              services purchasable at the counter or based on your use of the
              rental, such as driving cross border and, if any, pick-up and
              drop-off grace periods.
            </p>
          </CollapsibleSection>

          {/* Full Rental Terms */}
          <CollapsibleSection title="Full Rental Terms">
            <h3>Included in the rental price</h3>
            <h3>Insurance, Covers and Waivers</h3>
            <h3>Insurance Information</h3>
            <p>
              All rental cars must have Collision Damage Waiver (CDW) and Theft
              Protection (TP). Each policy will be either:
            </p>
            <ul>
              <li>Included</li>
              <li>Purchasable from the rental company</li>
              <li>
                Provided by another company (e.g., your credit card company)
              </li>
            </ul>

            <h3>Collision Damage Waiver (CDW)</h3>
            <p>
              If the car’s bodywork gets damaged, the most you’ll pay towards
              repairs covered by the Collision Damage Waiver is the damage
              excess. This cover is only valid if you stick to the terms of the
              rental agreement. It doesn’t cover other parts of the car (e.g.,
              glass, wheels, interior, roof, or undercarriage), or charges
              (e.g., for towing or off-road time), or anything in the car (e.g.,
              child seats, GPS devices, or personal belongings).
            </p>

            <h3>Theft Cover</h3>
            <p>
              If the car is stolen, the most you’ll pay towards replacement
              costs covered by the policy is the theft excess. This cover is
              only valid if you stick to the terms of the rental agreement.
            </p>

            <h3>Third-Party Liability (TPL)</h3>
            <p>
              Covers the driver’s liability for any injuries and property damage
              that are included in the policy. It does not cover injuries to the
              driver or damage to the rental car. This cover is only valid if
              you stick to the terms of the rental agreement.
            </p>

            <h3>Loss or Damage</h3>
            <p>
              If the car is stolen, or seriously damaged, or damaged in an
              incident involving someone else, please contact the rental company
              and the police immediately. If you can't provide the necessary
              documents from the police, you'll be liable for the full cost of
              replacing/repairing the car. If the car gets slightly damaged, and
              no one else is involved, please contact the rental company
              immediately.
            </p>
            <p>
              The rental company is not liable for the loss of / theft of /
              damage to any belongings in the car, during or after the rental.
            </p>
            <p>
              Damage to the car will be charged by the car hire company after it
              is dropped off and will incur a Damage Administration fee on top
              of the amount deducted from the excess.
            </p>

            <h3>Not Included in the Rental Cover (CDW & TP)</h3>
            <p>
              Loss of / theft of / damage to: keys, aerials, jack, safety
              triangles and vests, windscreen wipers, petrol cap, luggage
              covers, or any other fixed or mobile component of the car.
            </p>

            <h3>Roadside Assistance</h3>
            <p>
              When you pick your car up, please make sure you find out where the
              paperwork is kept. In most cases, it’ll be in the glove box or
              attached to the sun visor.
            </p>

            <h3>Mileage / Kilometers</h3>
            <p>Your rental includes unlimited free kilometers.</p>
            <p>
              Changing the length of your rental can affect how many kilometers
              you can drive for free. It can also affect how much you will pay
              for each additional kilometer.
            </p>

            <h3>Not Included in the Rental Price</h3>

            <h3>Local Charges</h3>
            <p>
              This section shows charges that you’ll pay at the counter because
              of (a) where you're picking your car up, (b) who's driving, and
              (c) where you go during your rental. It doesn’t show other
              over-the-counter costs, such as charges for fuel, child seats, or
              additional services.
            </p>

            <h3>Fines</h3>
            <p>
              You are responsible for any charges and fines, including tolls,
              congestion charges, speeding tickets, and parking or traffic
              fines.
            </p>

            <h3>Mandatory Coverage</h3>
            <p>
              At the car hire counter, you may decide to buy additional cover to
              reduce or remove your excess, or to cover things your Collision
              Damage Waiver (CDW) doesn't, such as tires and windscreen.
            </p>
            <p>
              Please note: If you do, the contract will be between you and the
              rental company – so you’ll need to contact them if you're
              dissatisfied with the policy or the cover it provides.
            </p>

            <h3>Credit Card Charges</h3>
            <p>
              If this applies to you, the cost is 2% of the car rental price
              including tax.
            </p>

            <h3>Additional Drivers</h3>
            <p>
              The main driver (the person named on the booking) must be present
              when you pick the car up and must be the one who pays anything
              charged at the counter. There may be a daily fee for additional
              drivers. The rental terms, including age-related restrictions and
              fees, apply to any additional drivers.
            </p>

            <h3>Additional Fees at Drop-off</h3>
            <p></p>

            <h3>After Your Rental</h3>
            <p>
              If you’ve incurred any charges, such as speeding tickets or unpaid
              congestion charges, the car hire company will try to contact you
              when the authorities ask them who was driving. This could be
              months after your rental – and you’ll have to pay the company’s
              administration fee as well as the original charge.
            </p>
          </CollapsibleSection>

          {/* What you need at Pick-up */}
          <CollapsibleSection title="What You Need at Pick-up">
            <h3>Acceptable Forms of Payment</h3>

            <h3>Accepted Credit Cards</h3>
            <p>
              The main driver must be in possession of a credit card in their
              own name when picking up the car. The card must have enough
              available funds to cover the excess/deposit amount (which will be
              blocked on the card for the duration of the rental). The card will
              also be required to pay for local fees, extra equipment, and
              anything purchased additionally at the car hire counter.
            </p>
            <p>
              Please bring a letter from your credit card company confirming
              that you have the right cover for this rental. If you can’t supply
              this proof, the counter staff will insist that you buy the cover
              at the counter.
            </p>

            <h3>Rules Around Payment Cards</h3>
            <ul>
              <li>
                If you pay by credit card, your bank may charge you an
                international transaction fee.
              </li>
              <li>
                The payment card(s) used at the rental counter must be in the
                name of the main driver (the person named on the booking).
              </li>
              <li>
                Credit cards need to be activated for international transactions
                and for payments abroad.
              </li>
              <li>
                You'll need to know the PIN of the payment card you are planning
                to use to pre-authorize your deposit.
              </li>
            </ul>

            <h3>Accepted Payment Cards</h3>
            <p>
              The counter staff can only accept the following credit cards for
              the deposit (you’ll need to have the physical card with you -
              prepaid, recharge, or virtual cards are not accepted):
            </p>
            <ul>
              <li>MasterCard</li>
              <li>Visa</li>
              <li>American Express</li>
            </ul>

            <h3>License Requirements</h3>
            <p>
              When picking the car up, the main driver and any additional
              drivers will need to provide a full driving license in their name.
            </p>
            <ul>
              <li>Digital driving licenses will not be accepted.</li>
              <li>
                If you’ve had your driving license renewed and it only shows the
                date it was renewed, you'll need to prove when it was originally
                issued.
              </li>
              <li>
                All drivers must have held their driving license for at least 12
                months.
              </li>
              <li>
                It is each driver’s responsibility to find out what paperwork
                they need before driving in another country. For example, you
                may need a visa and/or International Driving Permit as well as
                your driving license.
              </li>
              <li>
                Each driver will need to provide a valid driving license. If it
                is written in non-Latin characters, they'll also need to provide
                a valid International Driving Permit or a certified translation.
                Any driver with a driving license from outside Europe is advised
                to have an International Driving Permit as well.
              </li>
            </ul>

            <h3>Forms of Identification</h3>
            <p>At the counter, you'll need to provide:</p>
            <ul>
              <li>Each driver's full, valid driving license</li>
              <li>Your rental voucher</li>
              <li>Additional documents</li>
              <li>A credit card in the main driver's name</li>
              <li>Each driver's passport or ID card</li>
            </ul>

            <h3>Rental Voucher / eVoucher</h3>
            <p>
              At pick-up, the car hire company will require EITHER a printed
              copy of the customer's voucher OR an eVoucher. If this is not
              presented, the car hire company may not allow the car to be picked
              up or may charge again for the rental.
            </p>
            <p>
              When you booked your car, you booked a certain make and model ‘or
              similar’ (similar’ means same gearbox, similar size, similar
              engine, etc.). The counter staff may provide a different car - not
              the exact make and model that you were expecting.
            </p>

            <h3>Age Requirements</h3>
            <p>To drive this car, you must be at least 18.</p>

            <h3>Arrival Details</h3>
            <p>
              If you are arriving by plane, the car hire company needs your
              arrival flight number at least 3 days before your rental is due to
              start. If you do not provide your flight number, the car hire
              company cannot be held responsible if your plane is delayed and as
              a result your car is not available. Please ensure that you enter
              your flight number and not any other number the airline may have
              sent you, such as a confirmation number.
            </p>
            <p>
              If your flight is delayed, please call the rental counter during
              their opening hours.
            </p>
          </CollapsibleSection>

          {/* Deposits, Excess and Cover */}
          <CollapsibleSection title="Deposits, Excess and Cover">
            <h3>Cover Information</h3>
            <p>
              When you pick the car up, please make sure you read any terms and
              conditions before signing the rental agreement, including any
              terms of additional products you may purchase at the counter. It's
              very important that you understand the agreement/policy’s
              exclusions and limits, as well as the rules about anything you pay
              for at the rental counter.
            </p>

            <h3>Deposit / Excess</h3>
            <p>The car has a theft excess of NZ$2,300.00 including tax.</p>
            <p>
              The rental company will require a security deposit of NZ$2,300.00
              when you pick your car up. The deposit will be returned after the
              rental, if all conditions have been met.
            </p>
            <p>The car has a damage excess of NZ$2,300.00 including tax.</p>
            <p>
              When you pick your car up, the counter staff will require a
              security deposit for the car. They may also require a security
              deposit for fuel in the tank and for certain extras (e.g., a child
              seat or GPS). After your rental, your deposit(s) will be returned.
            </p>
            <p>
              Due to fluctuating foreign exchange rates and other possible
              banking charges, the car hire company cannot be held responsible
              for any difference between the amount paid and the amount
              refunded.
            </p>
            <p>
              The Collision Damage Waiver and Theft Protection policies come
              with an ‘excess’. The excess is the amount that you will need to
              pay before the policy covers the rest of the cost (for anything
              the policy covers).
            </p>

            <h3>Additional Cover</h3>
            <p>
              At the car hire counter, you may decide to buy additional cover to
              reduce or remove your excess, or to cover things your Collision
              Damage Waiver (CDW) doesn't, such as tires and windscreen.
            </p>
            <p>
              Please note: If you do, the contract will be between you and the
              rental company – so you’ll need to contact them if you're
              dissatisfied with the policy or the cover it provides.
            </p>
            <p>
              If you’re involved in a 'single vehicle accident' and/or the
              bodywork is particularly badly damaged, the excess will be higher.
            </p>

            <h3>Cover Arrangements</h3>
            <p>
              Please make sure you read the terms and conditions of the cover
              you receive when signing the rental agreement at pick-up, to find
              out about the policy’s exclusions and limits.
            </p>
            <p>
              Policies normally exclude things such as windscreens, glass,
              wheels, tires, undercarriage, interior, personal belongings,
              towing charges & off-road time – as well as any ‘extra equipment’
              hired from the car hire company, such as child seats and GPS
              devices.
            </p>
            <p>
              Note that your cover will be invalidated by negligence, refueling
              errors, or breaking the terms of the rental agreement (for
              example, by driving under the influence of alcohol or drugs).
            </p>

            <h3>Administration Charges</h3>
            <p>
              Damage to the car will be charged by the car hire company after it
              is dropped off and will incur a Damage Administration fee on top
              of the amount deducted from the excess.
            </p>
            <p>
              An Immobilization Charge is payable in the event of an accident.
              This is in addition to the amount deducted from excess and is
              compulsory. This charge is calculated against the car group and
              the number of repair days.
            </p>
            <p>
              If the car is damaged or stolen, the rental company will charge an
              administration fee. If you pay for damage/theft with a credit
              card, they'll charge an additional administration fee.
            </p>
            <p>
              Once the rental is completed, customers need to return to the
              rental office to ask for a refund of the deposit. The deposit must
              be repaid against the same payment card originally used.
            </p>
          </CollapsibleSection>

          {/* Extra Services (Payable at Counter) */}
          <CollapsibleSection title="Extra Services (Payable at Counter)">
            <h3>One-Way Rules</h3>
            <p>
              If you arrange to pick the car up in one location and drop it off
              in another, the car hire company may charge a one-way fee to cover
              the cost of returning the car to the original location.
            </p>
            <p>
              Prior notification is required for all one-way rentals. If you
              drop the car off at a location other than the one originally
              agreed, you will incur a 'Penalty' from the car hire company.
            </p>

            <h3>Cross-Border and Boundary Rules</h3>
            <p>
              Please note that cross-border travel is not allowed with this car
              hire company.
            </p>

            <h3>Outside Office Hours</h3>
            <p>
              Out-of-hours service available on request: pick up/drop off the
              car outside the counter’s normal opening hours. There will be a
              fee of NZ$50.00, which you will pay when you sign the rental
              agreement.
            </p>

            <h3>Deliveries and Collections</h3>
            <p>A delivery/collection service is not available.</p>

            <h3>Taxes & Fees</h3>
            <p>
              Any additional services will be subject to any applicable sales
              taxes and location fees.
            </p>

            <h3>Pet Policy</h3>
            <p>
              No animals, except service dogs, are allowed in the car, as this
              can cause severe allergic reactions in future customers. If you
              let any animal in the car, the rental company will charge a
              penalty fee and a cleaning fee.
            </p>

            <h3>Rental Extensions</h3>
            <p>
              If you need the car, including any extras, for longer than
              originally agreed, please call the pick-up counter (you’ll find
              their phone number on your rental agreement). If you don’t give
              them enough notice, there may be a penalty fee on top of the
              additional cost. You’ll also need to sign a new rental agreement
              with the rental company and arrange insurance to cover the
              additional day(s).
            </p>

            <h3>Extra Equipment</h3>
            <h4>Pay for Local Extras Equipment</h4>
            <p>
              As explained during the booking process, extras (child seats, GPS,
              etc.) will be:
            </p>
            <ul>
              <li>Included in the cost of your rental, or</li>
              <li>Ordered and paid for when booking your car, or</li>
              <li>
                Requested when booking your car and paid for at pick-up (in
                which case, we can’t guarantee that they’ll be available, or
                that the price will be the same).
              </li>
            </ul>

            <h3>Extra Equipment Conditions</h3>
            <ul>
              <li>
                Additional extras will be subject to any applicable sales taxes
                and location taxes.
              </li>
              <li>
                If an extra is lost, damaged, or stolen, you'll pay a
                replacement fee.
              </li>
              <li>
                If the car is picked up in one location and dropped off in
                another, the rental company may charge a One-Way Fee to cover
                the cost of returning an extra to the original location.
              </li>
              <li>
                Please note counter staff aren’t trained (or allowed) to fit
                baby/ child/booster seats. You will need to fit these yourself.
              </li>
            </ul>

            <h3>Additional Drivers</h3>
            <p>
              The car hire company’s age-related charges and restrictions will
              apply to all additional drivers.
            </p>
          </CollapsibleSection>

          {/* Important Information */}
          <CollapsibleSection title="Important Information">
            <p>
              By making this booking, you’re confirming that you have read and
              accepted the rental terms.
            </p>
            <p>
              At the rental counter, customers will also sign a rental agreement
              before they give you the key. It’s important that you read about
              that agreement carefully - and if anything isn’t clear, discuss it
              with the counter staff before you sign.
            </p>
            <p>
              Your rental company reserves the right to refuse a car if, in the
              counter staff’s opinion, the driver is unfit to drive or anyone in
              the group is threatening, abusive, or in any way endangering the
              health and safety of other people. If this happens, you will not
              be entitled to any refund or compensation.
            </p>
            <p>
              Please see ‘What you need at pick-up’ above - and note that the
              counter staff will not provide a car unless all requirements (age,
              driving license, payment card, documentation, etc.) are met. If
              this happens, you will not be entitled to any refund or
              compensation.
            </p>
            <p>
              Before you drive off, you must check the car and make sure that
              any damage is reported to the counter staff and noted on the
              rental agreement. Otherwise, you may end up being liable for that
              damage.
            </p>

            <h3>Road Rules</h3>
            <p>
              When you rent a car, you agree to use it responsibly. You must
              not:
            </p>
            <ul>
              <li>
                Drive under the influence of alcohol, drugs, or any other type
                of narcotic substances
              </li>
              <li>
                Transport any inflammable or dangerous goods, or any toxic,
                corrosive, radioactive, or otherwise harmful substances
              </li>
              <li>
                Carry anything which, because of its smell and/or condition,
                could damage the car or cause the rental company to lose time or
                money
              </li>
              <li>Transporting live animals</li>
              <li>
                Install a roof rack, luggage carrier, or anything similar,
                unless supplied by the rental company
              </li>
              <li>Rent the car to anyone else</li>
              <li>Carry passengers for hire or reward (taxi)</li>
              <li>Participate in rallies, competitions, or trials</li>
              <li>Give driving lessons</li>
              <li>Towing another vehicle</li>
              <li>
                Travel on unpaved roads or roads where the surface or state of
                repair could damage the car
              </li>
              <li>
                In any way break the highway code, road traffic laws, or other
                laws.
              </li>
            </ul>
            <p>
              Seatbelt - Whatever country you’re driving in, driver and
              passengers must wear seatbelts if fitted, wherever they’re sitting
              in the car.
            </p>
            <p>
              Please be aware that all cars have a non-smoking policy. Fines
              will apply if this policy is not adhered to.
            </p>
            <p>
              To make sure you understand the rules and laws about driving hire
              cars at home and abroad, it’s essential you read the Terms &
              Conditions in full before you leave to pick your car up.
            </p>
            <p>
              In particular, please make sure you know what documents and other
              paperwork you must take with you (e.g., driving license, forms of
              identification, and payment cards) and what you’ll need to pay for
              at the car hire counter. If you don’t have everything you need,
              the car hire company may not let you pick the car up.
            </p>
            <p>
              If you intend to do any city driving, there may be traffic
              restrictions based on your car’s license plate, so please tell the
              counter staff when you go to pick up your car.
            </p>

            <h3>On the Road</h3>
            <p>
              Accident or Breakdown - In the event of a breakdown, accident, or
              mechanical difficulties, you must call the car hire company
              immediately. They must give authority to repair or replacement
              cars. Please make copies of all documentation you are asked to
              complete. This may be needed if you wish to make a claim. For
              accidents, a police report and an incident report from the car
              hire counter will be required.
            </p>
            <p>
              If you are having any problems with your rental car, please call
              the rental company.
            </p>
          </CollapsibleSection>

          {/* Important Car Hire Company Information */}
          <CollapsibleSection title="Important Car Hire Company Information">
            <h3>Price Calculation</h3>
            <p>
              Please note that exchange rates can fluctuate - and no one in the
              car rental industry can do anything about this. As a result, you
              might find that the charges on your statement aren’t an exact
              match with the price you were told while booking. The same goes
              for any refund you may receive.
            </p>
            <p>
              Prices are based on the pick-up and drop-off times and dates that
              you agree before your rental starts. If you pick the car up any
              later or bring it back any earlier, you will not receive a refund
              for unused time.
            </p>
            <p>
              If, at pick-up, you choose to take a different car, there may be
              an additional charge for this – even if the new car is smaller
              than the one you had booked.
            </p>

            <h3>Penalty Fees</h3>
            <p>
              An Immobilization Charge is payable in the event of an accident.
              This is in addition to the amount deducted from excess and is
              compulsory. This charge is calculated against the car group and
              the number of repair days.
            </p>
            <p>
              Lost Keys - If you lose your key(s), you will be charged the cost
              of replacement.
            </p>
            <p>
              If you've not returned the car after your agreed drop-off time,
              the insurance will no longer be valid. Plus, you will be charged a
              penalty fee as well as the cost of another day's rental.
            </p>

            <h3>Grace Period</h3>
            <p>
              You must be at the rental counter by your pick-up time: if you’re
              late, the car may no longer be available, and you won’t be
              entitled to a refund. If you think you might be late, it's vital
              that you contact the rental company at least 30 minutes before
              your pick-up time, even if it's because of a flight delay and
              you’ve provided your flight number.
            </p>
            <p>
              There will be a 59-minute grace period for the return of all
              vehicles. If the car is returned after this time, the insurance
              will no longer be valid, and the customer will have to pay the
              late penalty fee plus the daily rate.
            </p>
            <p>
              In case of delay, reservations will be made for 59 minutes from
              the specified reservation time, after which they will be classed
              as a no-show.
            </p>

            <h3>Valeting</h3>
            <p>
              The car hire company will charge you if the car needs more than a
              reasonable amount of cleaning after your rental.
            </p>
          </CollapsibleSection>

          {/* Privacy Policy */}
          <CollapsibleSection title="Privacy Policy">
            <ul>
              <li>
                We collect and use your personal information in accordance with
                New Zealand's Privacy Act 2020.
              </li>
              <li>
                Your information is used solely for rental purposes and will not
                be shared with third parties without your consent.
              </li>
            </ul>
          </CollapsibleSection>

          {/* Company Registration Details */}
          <CollapsibleSection title="Company Registration Details">
            <h3>Vip Car Rentals</h3>
            <ul>
              <li>
                Registered Address: 123 Main Street, Invercargill 9810, New
                Zealand
              </li>
              <li>Contact Number: 0272476621</li>
            </ul>
          </CollapsibleSection>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndConditions;
