import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";

import driveImg from "../assets/all-images/drive.jpg";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  We Are Committed To Provide Safe Ride Solutions
                </h2>

                <p className="section__description">
                  At VIP Car Rentals, we understand the importance of safety,
                  comfort, and reliability when it comes to car rentals. Located
                  in the heart of Invercargill, New Zealand, we are dedicated to
                  offering you a seamless rental experience with a wide
                  selection of well-maintained vehicles suitable for all your
                  travel needs.
                </p>

                <p className="section__description">
                  Whether you're exploring the beautiful landscapes of
                  Southland, visiting friends and family, or just getting around
                  town, our fleet is ready to serve you. Our commitment is to
                  provide you with a vehicle that suits your style and budget,
                  ensuring a hassle-free journey every time.
                </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i className="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+64272476621</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
