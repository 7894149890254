// CarDetails.jsx

import React, { useEffect, useState } from "react";
import carData from "../assets/data/carData";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
import BookingForm from "../components/UI/BookingForm";
import PaymentMethod from "../components/UI/PaymentMethod";

const CarDetails = () => {
  const { slug } = useParams();
  const [bookingData, setBookingData] = useState(null);
  const [showPayment, setShowPayment] = useState(false);

  const singleCarItem = carData.find((item) => item.carName === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [singleCarItem]);

  const handleBookingSubmit = (data) => {
    setBookingData(data);
    setShowPayment(true);
  };

  return (
    <Helmet title={singleCarItem.carName}>
      <section>
        <Container>
          <Row>
            {/* Car Image */}
            <Col lg="6" md="6" sm="12" className="mb-4 mb-md-0">
              <img
                src={singleCarItem.imgUrl}
                alt={singleCarItem.carName}
                className="w-100"
              />
            </Col>

            {/* Car Details */}
            <Col lg="6" md="6" sm="12">
              <div className="car__info">
                <h2 className="section__title">{singleCarItem.carName}</h2>

                <div className="d-flex align-items-center gap-4 mb-4 mt-3 flex-wrap">
                  <h6 className="rent__price fw-bold fs-4 mb-0">
                    ${singleCarItem.price}.00 / Day
                  </h6>
                  <span className="d-flex align-items-center gap-2">
                    <span style={{ color: "#E32129" }}>
                      <i className="ri-star-s-fill"></i>
                      <i className="ri-star-s-fill"></i>
                      <i className="ri-star-s-fill"></i>
                      <i className="ri-star-s-fill"></i>
                      <i className="ri-star-s-fill"></i>
                    </span>
                    ({singleCarItem.rating} ratings)
                  </span>
                </div>

                <p className="section__description">
                  {singleCarItem.description}
                </p>

                {/* First row of car features */}
                <div className="d-flex align-items-center gap-4 flex-wrap mt-3">
                  <span className="d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-roadster-line"
                      style={{ color: "#E32129" }}
                    ></i>
                    {singleCarItem.model}
                  </span>
                  <span className="d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-settings-2-line"
                      style={{ color: "#E32129" }}
                    ></i>
                    {singleCarItem.automatic}
                  </span>
                  <span className="d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-timer-flash-line"
                      style={{ color: "#E32129" }}
                    ></i>
                    {singleCarItem.speed}
                  </span>
                  <span className="d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-user-3-line"
                      style={{ color: "#E32129" }}
                    ></i>
                    {singleCarItem.seats} Seats
                  </span>
                </div>

                {/* Second row of car features */}
                <div className="d-flex align-items-center gap-4 flex-wrap mt-3">
                  <span className="d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-map-pin-line"
                      style={{ color: "#E32129" }}
                    ></i>
                    {singleCarItem.gps}
                  </span>
                  <span className="d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-wheelchair-line"
                      style={{ color: "#E32129" }}
                    ></i>
                    {singleCarItem.seatType}
                  </span>
                  <span className="d-flex align-items-center gap-1 section__description">
                    <i
                      className="ri-building-2-line"
                      style={{ color: "#E32129" }}
                    ></i>
                    {singleCarItem.brand}
                  </span>
                </div>
              </div>
            </Col>

            {/* Booking Form or Payment Method */}
            {!showPayment && (
              <Col lg="12" className="mt-5">
                <div className="booking-info mt-5">
                  <h5 className="mb-4 fw-bold">Booking Information</h5>
                  <BookingForm onBookingSubmit={handleBookingSubmit} />
                </div>
              </Col>
            )}

            {showPayment && (
              <Col lg="12" className="mt-5">
                <div className="payment__info mt-5">
                  <h5 className="mb-4 fw-bold">Payment Information</h5>
                  <PaymentMethod
                    amount={singleCarItem.price * 100} // Convert to cents
                    bookingData={bookingData}
                    carItem={singleCarItem}
                  />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;
