import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { db } from "../../firebase"; // Adjust path if needed
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import "../../styles/payment-method.css";

// Load Stripe using your publishable key from .env
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({ amount, bookingData, carItem }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // Call your Cloud Function to get a PaymentIntent client secret
    fetch(
      "https://us-central1-vipcars-997ad.cloudfunctions.net/createPaymentIntentNZD",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount }), // amount in cents
      }
    )
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((error) => console.error("Error fetching client secret:", error));
  }, [amount]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements || !clientSecret) {
      setProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      // Confirm the Card Payment with Stripe
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: `${bookingData.firstName} ${bookingData.lastName}`,
              email: bookingData.email || "",
              phone: bookingData.phoneNumber || "",
            },
          },
        }
      );

      if (error) {
        console.error("[Payment error]", error);
        alert(`Payment failed: ${error.message}`);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        console.log("Payment succeeded:", paymentIntent);

        // 1) Save booking details to Firestore
        const bookingId = await saveBookingToFirestore(paymentIntent.id);

        // 2) Optionally send a confirmation email (if your function is set up)
        await sendConfirmationEmail(bookingId, bookingData);

        // 3) Handle success in the UI
        alert(
          "Payment succeeded! Booking confirmed. A confirmation email has been sent."
        );
        // You could navigate to a success page or reset form, etc.
        // e.g. navigate("/booking-success");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      alert("An unexpected error occurred while processing the payment.");
    } finally {
      setProcessing(false);
    }
  };

  /**
   * Saves the booking to Firestore, ensuring no `undefined` fields
   * and using the correct field names from `BookingForm.jsx`.
   */
  const saveBookingToFirestore = async (paymentIntentId) => {
    try {
      const docRef = await addDoc(collection(db, "bookings"), {
        carId: carItem.id,
        carName: carItem.carName || "",
        userName: `${bookingData.firstName || ""} ${
          bookingData.lastName || ""
        }`,
        email: bookingData.email || "",
        phoneNumber: bookingData.phoneNumber || "",
        pickupDate: bookingData.pickupDate || null,
        pickupTime: bookingData.pickupTime || null,
        dropoffDate: bookingData.dropoffDate || null,
        dropoffTime: bookingData.dropoffTime || null,
        promoCode: bookingData.promoCode || "",
        emergencyContact: bookingData.emergencyContact || "",
        driversLicense: bookingData.driversLicense || "",
        passportNumber: bookingData.passportNumber || "",
        message: bookingData.message || "",
        amount: amount / 100, // Convert cents to dollars
        paymentIntentId,
        timestamp: serverTimestamp(),
        status: "paid",
      });

      console.log("Booking saved successfully with ID:", docRef.id);
      return docRef.id;
    } catch (error) {
      console.error("Error saving booking:", error);
      throw error;
    }
  };

  /**
   * Calls your Cloud Function to send a confirmation email
   * to both the user and client (if desired).
   */
  const sendConfirmationEmail = async (bookingId, bookingData) => {
    try {
      const response = await fetch(
        "https://us-central1-vipcars-997ad.cloudfunctions.net/sendConfirmationEmail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            bookingId: bookingId,
            userEmail: bookingData.email,
            clientEmail: "admin@vipcars.co.nz",
          }),
        }
      );

      if (!response.ok) {
        console.error("Failed to send email confirmation");
      } else {
        console.log("Confirmation email sent successfully");
      }
    } catch (error) {
      console.error("Error sending confirmation email:", error);
    }
  };

  // Customize the appearance of the CardElement
  const cardStyle = {
    style: {
      base: {
        color: "#1a1a40",
        fontFamily: "Arial, sans-serif",
        fontSize: "16px",
        "::placeholder": {
          color: "#999",
        },
      },
      invalid: {
        color: "#e32129",
      },
    },
    hidePostalCode: true,
  };

  return (
    <form onSubmit={handleSubmit} className="payment__form">
      <CardElement options={cardStyle} className="payment__card-element" />
      <button
        type="submit"
        disabled={!stripe || processing}
        className="payment__button"
      >
        {processing ? "Processing..." : `Pay $${(amount / 100).toFixed(2)}`}
      </button>
    </form>
  );
};

const PaymentMethod = ({ amount, bookingData, carItem }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        amount={amount}
        bookingData={bookingData}
        carItem={carItem}
      />
    </Elements>
  );
};

export default PaymentMethod;
